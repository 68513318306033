import { createRouter, createWebHistory } from 'vue-router';

const Index = () => import('@/views/Index.vue');
const AdminDashboard = () => import('@/views/AdminDashboard.vue');
const Register = () => import('@/components/Register.vue');
const ViewSquads = () => import('@/components/ViewSquads.vue');
const AdminRegistrations = () => import('@/components/AdminRegistrations.vue');
const AdminMatch = () => import('@/components/AdminMatch.vue');
const ProfileManagement = () => import('@/components/ProfileManagement.vue');
const MatchView = () => import('@/components/MatchView.vue');
const YappyTest = () => import('@/views/YappyTest.vue');  // Import the new YappyTest component

const routes = [
  { path: '/', component: Index },
  { path: '/admin', component: AdminDashboard },
  { path: '/register/:id/:edit?', name: 'Register', component: Register },
  { path: '/edit-match/:id', name: 'edit-match', component: AdminMatch },
  { path: '/view-squads/:id', component: ViewSquads },
  { path: '/admin-registrations/:id', component: AdminRegistrations },
  { path: '/create-match', name: 'create-match', component: AdminMatch },
  { path: '/manage-profile', component: ProfileManagement },
  { path: '/match/:id', component: MatchView },
  { path: '/yappy', component: YappyTest },  // Use the new YappyTest component
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export default router;
